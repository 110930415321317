// src/prompt.js
const promptTemplate = (userPrompt) => `
Create a cutting-edge, visually stunning email template for ${userPrompt}.

Design Requirements:
1. Aesthetic: Ultra-modern, sleek, and professional-grade design that stands out in any inbox.
2. Layout: Utilize advanced responsive design techniques with CSS Grid and Flexbox for a fluid, adaptive layout across all devices.
3. Typography:
   - Implement sophisticated font pairing, combining a maximum of two complementary typefaces.
   - Apply meticulous attention to font sizes, weights, and line heights to create a clear visual hierarchy.
   - Utilize custom letter-spacing and text transforms for headings and call-to-action elements.

4. Color Scheme:
   - Develop a harmonious, psychologically impactful color palette using advanced color theory principles.
   - Incorporate subtle gradients or color overlays to add depth and sophistication.
   - Ensure optimal contrast ratios for readability and accessibility.

5. Imagery and Icons:
   - Integrate high-quality, professionally styled images that align perfectly with the template's purpose and mood.
   - Use SVG icons for crisp, scalable visual elements that enhance the overall design.
   - Implement subtle hover effects or micro-interactions on clickable elements.

6. White Space and Balance:
   - Strategically use negative space to create a sense of elegance and improve readability.
   - Ensure perfect alignment and spacing between all elements for a polished look.

7. Content Sections:
   - Design an attention-grabbing header with a compelling hero image or animated GIF.
   - Create visually distinct sections for main content, features/benefits, testimonials, and call-to-action areas.
   - Implement an aesthetically pleasing and informative footer with social media links and essential company information.

8. Interactive Elements:
   - Design eye-catching, irresistible call-to-action buttons with hover effects.
   - Include subtle animations or transitions to guide the reader's attention (using CSS animations).

9. Customization:
   - Use dynamic content placeholders for easy personalization (e.g., {user_name}, {company_name}).
   - Implement conditional content blocks for tailored messaging based on recipient segments.

10. Technical Considerations:
    - Ensure cross-email-client compatibility by using email-safe HTML and CSS techniques.
    - Optimize images for fast loading times without sacrificing quality.
    - Include fallback options for email clients that don't support certain features.

11. Branding:
    - Seamlessly incorporate brand elements (logo, colors, tone) while maintaining a fresh, innovative look.
    - Create a unique visual identity that sets the email apart from competitors.

12. Footer:
    - Include a dynamic year in the copyright notice that updates automatically.
    - Add clear, legally compliant unsubscribe options and privacy policy links.

Additional Instructions:
- Push boundaries: Think outside the conventional email design box. Incorporate unexpected elements or layouts that capture attention without sacrificing usability.
- Emotional impact: Design with the goal of evoking a specific emotion or reaction from the recipient.
- A/B testing readiness: Create subtle variations of key elements (e.g., CTA buttons, headlines) to facilitate easy A/B testing.

Return only the clean, production-ready HTML code without any comments or explanations. Ensure all placeholder content is relevant and professional-sounding.
`;

export default promptTemplate;

