// src/components/PrivacyPolicy.js
import React from 'react';
import '../css/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>

      <p>Last Updated: September 27, 2024</p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to <strong>GateAI.Email AI Email HTML Generator</strong> ("we," "us," or "our"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our service.
      </p>

      <h2>2. Information We Collect</h2>
      <p>
        - <strong>Email Address:</strong> Collected during registration to create and manage your user account.<br />
        - <strong>API Keys:</strong> Provided by users within their personal dashboard for their own use. We do not store or utilize these API keys.<br />
        - <strong>Usage Data:</strong> Collected automatically through the use of Google Analytics to monitor and analyze how users interact with our service. This includes information such as your device's Internet Protocol (IP) address, browser type, browser version, the pages of our service that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data.
      </p>

      <h2>3. How We Use Your Information</h2>
      <p>
        - <strong>Account Management:</strong> To create, maintain, and manage your user account.<br />
        - <strong>Communication:</strong> To send you important updates, notifications, and promotional materials related to our service.<br />
        - <strong>Support:</strong> To provide customer support and respond to your inquiries.<br />
        - <strong>Service Improvement:</strong> To analyze usage patterns and improve the functionality and performance of our service.<br />
        - <strong>Compliance:</strong> To comply with legal obligations and enforce our Terms of Service.
      </p>

      <h2>4. Payment Processing</h2>
      <p>
        All payment processing is handled securely by <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a>, a third-party payment processor. We do not store or have access to your payment information. For more details, please refer to Stripe's <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
      </p>

      <h2>5. Google Analytics and Cookies</h2>
      <p>
        We use Google Analytics to collect and analyze usage data to understand how users interact with our service. Google Analytics uses cookies to track and report website traffic. The information generated by the cookie about your use of the service (including your IP address) will be transmitted to and stored by Google on servers in the United States.
      </p>
      <p>
        <strong>Consent:</strong><br />
        By using our service, you consent to the processing of data about you by Google in the manner and for the purposes set out above. If you do not agree with our use of cookies and Google Analytics, please adjust your browser settings accordingly or refrain from using the service.
      </p>
      <p>
        <strong>Opt-Out:</strong><br />
        You can opt out of Google Analytics without affecting how you visit our website. For more information on how to opt out of being tracked by Google Analytics across all websites you use, visit <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">Google Analytics Opt-out Browser Add-on</a>.
      </p>

      <h2>6. Consent Management</h2>
      <p>
        We have implemented a consent banner on our website to obtain your explicit consent before using cookies and tracking technologies like Google Analytics. You can manage your cookie preferences at any time through the banner or by adjusting your browser settings.
      </p>

      <h2>7. Data Storage and Security</h2>
      <p>
        We implement industry-standard security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. Your personal information is stored on secure servers and is accessible only by authorized personnel.
      </p>

      <h2>8. Data Sharing and Disclosure</h2>
      <p>
        - <strong>Third-Party Services:</strong> We use Stripe for payment processing and Google Analytics for data analysis. Your payment information is handled directly by Stripe, and your usage data is processed by Google Analytics.<br />
        - <strong>Legal Requirements:</strong> We may disclose your information if required by law or to protect our rights and the rights of others.<br />
        - <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.
      </p>

      <h2>9. Your Rights</h2>
      <p>
        Depending on your location, you may have the following rights regarding your personal data:
        <ul>
          <li><strong>Access:</strong> You have the right to request access to your personal data.</li>
          <li><strong>Rectification:</strong> You can request the correction of inaccurate or incomplete data.</li>
          <li><strong>Deletion:</strong> You have the right to request the deletion of your data, subject to certain conditions.</li>
          <li><strong>Restriction of Processing:</strong> You can request the restriction of processing your data under certain circumstances.</li>
          <li><strong>Data Portability:</strong> You have the right to receive your data in a structured, commonly used, and machine-readable format.</li>
          <li><strong>Objection:</strong> You can object to the processing of your data under certain conditions.</li>
        </ul>
      </p>
      <p>
        To exercise these rights, please contact us at <a href="mailto:info@gateai.email">info@gateai.email</a>.
      </p>

      <h2>10. Children's Privacy</h2>
      <p>
        Our service is not intended for individuals under the age of 18. We do not knowingly collect personal information from minors. If we become aware that we have collected personal information from a minor without verification of parental consent, we will take steps to remove that information.
      </p>

      <h2>11. Data Security</h2>
      <p>
        We implement robust security measures to protect your data from unauthorized access, use, or disclosure. These measures include encryption, access controls, and secure data storage practices. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
      </p>

      <h2>12. Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated date. We encourage you to review this Privacy Policy periodically for any changes. Your continued use of the service after any modifications constitutes acceptance of the updated Privacy Policy.
      </p>

      <h2>13. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at:
        <ul>
          <li><strong>Email:</strong> <a href="mailto:info@gateai.email">info@gateai.email</a></li>
        </ul>
      </p>

      {/* Add additional sections as necessary */}
    </div>
  );
};

export default PrivacyPolicy;
