// src/App.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import HtmlEditor from './components/HtmlEditor';
import HtmlPreview from './components/HtmlPreview';
import UserDashboard from './components/UserDashboard';
import PrivateRoute from './components/PrivateRoute';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'codemirror/theme/material.css';
import 'codemirror/theme/base16-light.css';
import { HashRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom';
import './css/Variables.css';
import './css/Global.css';
import './css/Header.css';
import './css/MainSection.css';
import './css/Buttons.css';
import './css/Modal.css';
import './css/Auth.css';
import './css/Pricing.css';
import lightIcon from './img/light.svg';
import darkIcon from './img/dark.svg';
import menuIcon from './img/menu.svg'; // Импортируем иконку гамбургера
import Pricing from './components/Pricing';
import Feedback from './components/Feedback';
import Login from './components/Login';
import Register from './components/Register';
import Footer from './components/Footer'; // Импортируем Footer
import Faq from './components/Faq'; // Импортируем FAQ
import PrivacyPolicy from './components/PrivacyPolicy'; // Импортируем Privacy Policy
import TermsOfService from './components/TermsOfService'; // Импортируем Terms of Service
import { auth } from './firebase';
import templates from './templates';
import promptTemplate from './prompt';

function App() {
  const [html, setHtml] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);
  const [userPrompt, setUserPrompt] = useState('');
  const [theme, setTheme] = useState('light');
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const ws = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const [menuActive, setMenuActive] = useState(false); // Новое состояние для меню

  const location = useLocation(); // Хук для отслеживания маршрутов

  const irrelevantKeywords = ['illegal', 'hack', 'weapon', 'adult', 'unlawful'];

  // Функция для отслеживания просмотров страниц
  const trackPageView = (page) => {
    if (window.gtag) {
      window.gtag('config', process.env.REACT_APP_GA_TRACKING_ID, {
        page_path: page,
      });
    }
  };

  // Отслеживание изменений маршрутов
  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  // Отслеживание состояния аутентификации
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        if (!currentUser.emailVerified) {
          setIsAuthenticated(false);
          setUserEmail(null);
          toast.warn('Please verify your email address.', { position: 'bottom-right', autoClose: 4000 });
          await auth.signOut();
          return;
        }

        setIsAuthenticated(true);
        setUserEmail(currentUser.email);
      } else {
        setIsAuthenticated(false);
        setUserEmail(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleHtmlChange = useCallback((newHtml) => {
    setHtml(newHtml);
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
  };

  const handleGenerateHtml = () => {
    // Отслеживаем событие нажатия на кнопку Generate
    if (window.gtag) {
      window.gtag('event', 'generate_html', {
        event_category: 'User Actions',
        event_label: 'Generate Button',
        value: 1,
      });
    }

    if (irrelevantKeywords.some(keyword => userPrompt.toLowerCase().includes(keyword))) {
      toast.error('Invalid request. Please avoid illegal or irrelevant content.', { position: 'bottom-right', autoClose: 4000 });
      return;
    }

    if (isGenerating) return;
    setIsGenerating(true);
    setHtml('');
    toast.info('Generating...', { position: 'bottom-right', autoClose: 2000 });

    const generate = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          toast.error('You must be logged in to generate HTML.', { position: 'bottom-right', autoClose: 4000 });
          setIsGenerating(false);
          return;
        }

        const token = await user.getIdToken();

        ws.current = new WebSocket(`${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}/ws/`);

        ws.current.onopen = () => {
          setIsConnected(true);
          const message = { prompt: promptTemplate(userPrompt), token };
          console.log('Sending message:', message);
          ws.current.send(JSON.stringify(message));
        };

        ws.current.onmessage = (event) => {
          console.log('Received message:', event.data);
          try {
            const data = JSON.parse(event.data);
            if (data.text) {
              let text = data.text;
              // Удаляем лишние символы, не относящиеся к HTML
              text = text.replace(/```html\s*/g, '').replace(/```\s*/g, '');
              setHtml((prev) => prev + text);
            }
            if (data.end) {
              setIsGenerating(false);
              ws.current.close();
            }
            if (data.error) {
              toast.error(`Error: ${data.error}`, { position: 'bottom-right', autoClose: 4000 });
              setIsGenerating(false);
              ws.current.close();
            }
          } catch (parseError) {
            console.error('Error parsing received message:', parseError);
            toast.error('Received malformed message from server.', { position: 'bottom-right', autoClose: 4000 });
            setIsGenerating(false);
            ws.current.close();
          }
        };

        ws.current.onerror = (err) => {
          console.error('WebSocket error:', err);
          toast.error('WebSocket error occurred.', { position: 'bottom-right', autoClose: 4000 });
          setIsGenerating(false);
          ws.current.close();
        };

        ws.current.onclose = () => {
          setIsConnected(false);
          if (isGenerating) {
            setIsGenerating(false);
            toast.info('Connection closed. Generation might be incomplete.', { position: 'bottom-right', autoClose: 4000 });
          }
        };
      } catch (error) {
        console.error('Generate HTML error:', error);
        toast.error('Error: Unable to connect to server.', { position: 'bottom-right', autoClose: 4000 });
        setIsGenerating(false);
      }
    };

    generate();
  };

  const handleStopGeneration = () => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.close();
      setIsGenerating(false);
      toast.info('Generation stopped.', { position: 'bottom-right', autoClose: 2000 });
    }
  };

  const handleExportHtml = () => {
    // Отслеживаем событие экспорта HTML
    if (window.gtag) {
      window.gtag('event', 'export_html', {
        event_category: 'User Actions',
        event_label: 'Export HTML Button',
        value: 1,
      });
    }

    const blob = new Blob([html], { type: 'text/html' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'generated-template.html';
    link.click();
    toast.success('HTML file exported!', { position: 'bottom-right', autoClose: 2000 });
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      setIsAuthenticated(false);
      setUserEmail(null);
      toast.success('Logged out successfully!', { position: 'bottom-right', autoClose: 3000 });
    });
  };

  // Закрываем меню при изменении размера окна
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && menuActive) {
        setMenuActive(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuActive]);

  return (
    <div className={`app-container ${theme}`}>
      <header className="header">
        <Link to="/" className="logo" onClick={() => setMenuActive(false)}>[ G A T E ] Generate AI Templates for Emails</Link>

        <nav className={`nav ${menuActive ? 'active' : ''}`}>
          <Link to="/" onClick={() => setMenuActive(false)}>Home</Link>
          <Link to="/pricing" onClick={() => setMenuActive(false)}>Pricing</Link>
          {isAuthenticated && <Link to="/dashboard" onClick={() => setMenuActive(false)}>Dashboard</Link>}
          <Link to="/feedback" onClick={() => setMenuActive(false)}>Contact Us</Link>
          {/* Добавляем auth-links внутрь навигации для мобильной версии */}
          <div className="auth-links mobile-auth-links">
            {isAuthenticated ? (
              <div>
                <span>Welcome, {userEmail || 'User'}</span>
                <button onClick={() => { handleLogout(); setMenuActive(false); }}>Logout</button>
              </div>
            ) : (
              <>
                <button onClick={() => { setShowLogin(true); setMenuActive(false); }}>Login</button>
                <button onClick={() => { setShowRegister(true); setMenuActive(false); }}>Sign Up</button>
              </>
            )}
          </div>
        </nav>

        <div className="auth-links desktop-auth-links">
          {isAuthenticated ? (
            <div>
              <span>Welcome, {userEmail || 'User'}</span>
              <button onClick={handleLogout}>Logout</button>
            </div>
          ) : (
            <>
              <button onClick={() => setShowLogin(true)}>Login</button>
              <button onClick={() => setShowRegister(true)}>Sign Up</button>
            </>
          )}
        </div>

        <div className="theme-switcher" onClick={toggleTheme}>
          <img src={theme === 'light' ? lightIcon : darkIcon} alt="Toggle Theme" />
        </div>

        <div className="hamburger" onClick={() => setMenuActive(!menuActive)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>

      <Routes>
        <Route exact path="/" element={
          <main className="main-section">
            <h1>Create AI-Powered Email Templates</h1>
            <p>Transform your email campaigns with AI-generated HTML templates.</p>
            <div className="input-panel">
              <input
                type="text"
                value={userPrompt}
                onChange={(e) => setUserPrompt(e.target.value)}
                placeholder="Enter your prompt..."
                disabled={isGenerating}
              />
              <div className="buttons-container">
                <button
                  className="secondary"
                  onClick={() => setShowTemplates(true)}
                  disabled={isGenerating}
                >
                  Templates
                </button>
                <button
                  className="generate"
                  onClick={handleGenerateHtml}
                  disabled={isGenerating || !userPrompt.trim()}
                >
                  {isGenerating ? 'Generating...' : 'Generate'}
                </button>
                <button
                  className="stop"
                  onClick={handleStopGeneration}
                  disabled={!isGenerating}
                >
                  Stop
                </button>
                <button
                  className="export"
                  onClick={handleExportHtml}
                  disabled={!html.trim()}
                >
                  Export HTML
                </button>
              </div>
            </div>
            <div className="main-container">
              <HtmlEditor
                value={html}
                onChange={handleHtmlChange}
                theme={theme === 'light' ? 'base16-light' : 'material'}
              />
              <HtmlPreview html={html} />
            </div>
          </main>
        } />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/dashboard" element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <UserDashboard />
          </PrivateRoute>
        } />
        <Route path="/faq" element={<Faq />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        {/* Добавьте другие маршруты по мере необходимости */}
      </Routes>

      {showTemplates && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3 className="modal-title">Choose a Template</h3>
            <div className="template-list">
              {templates.map((template, index) => (
                <button
                  key={index}
                  className="template-button"
                  onClick={() => {
                    setHtml(template.html);
                    setShowTemplates(false);
                  }}
                >
                  {template.name}
                </button>
              ))}
            </div>
            <button className="modal-close" onClick={() => setShowTemplates(false)}>Close</button>
          </div>
        </div>
      )}

      {showLogin && (
        <div className="modal-overlay">
          <div className="modal-content">
            <Login
              setShowLogin={setShowLogin}
              setShowRegister={setShowRegister}
            />
            <button className="modal-close" onClick={() => setShowLogin(false)}>Close</button>
          </div>
        </div>
      )}

      {showRegister && (
        <div className="modal-overlay">
          <div className="modal-content">
            <Register
              setShowRegister={setShowRegister}
              setShowLogin={setShowLogin}
            />
            <button className="modal-close" onClick={() => setShowRegister(false)}>Close</button>
          </div>
        </div>
      )}

      <ToastContainer />

      {/* Добавляем Footer */}
      <Footer />
    </div>
  );
}

export default App;
