// src/components/Pricing.js
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import '../css/Pricing.css';
import { toast } from 'react-toastify';

// Initialize Stripe with the public key from environment variables
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function Pricing() {
  const [loading, setLoading] = useState(false);
  const [stripe, setStripe] = useState(null); // State for Stripe
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [activePlans, setActivePlans] = useState([]);
  const navigate = useNavigate();

  // Initialize Stripe and fetch user subscriptions on mount
  useEffect(() => {
    const initialize = async () => {
      const stripeInstance = await stripePromise;
      setStripe(stripeInstance);

      const user = auth.currentUser;
      if (user) {
        const token = await user.getIdToken();
        const response = await fetch('https://gateai.email/api/user-dashboard', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserSubscriptions(data.subscriptions);
          const active = data.subscriptions
            .filter(sub => sub.status === 'active')
            .map(sub => sub.plan);
          setActivePlans(active);
        }
      }
    };
    initialize();
  }, []);

  const plans = [
    {
      name: 'Standard',
      price: '$10',
      priceId: process.env.REACT_APP_PRICE_STANDARD, // Use environment variable
      features: ['Unlimited email template generations'],
      popular: true,
    },
    {
      name: 'PRO',
      price: '$2.50',
      priceId: process.env.REACT_APP_PRICE_PRO, // Use environment variable
      features: ['Unlimited email template generations with YOUR own API key'],
      popular: false,
    },
  ];

  const handleCheckout = async (priceId) => {
    setLoading(true);

    try {
      // Get current user from Firebase Authentication
      const user = auth.currentUser;
      if (!user) {
        console.error('User is not logged in.');
        alert('You need to be logged in to select a plan.');
        return;
      }

      // Get token for server authentication
      const token = await user.getIdToken();

      // Request server to create Stripe Checkout session
      const response = await fetch('https://gateai.email/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ priceId, email: user.email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error creating checkout session:', errorData.error);
        alert(`Error: ${errorData.error}`);
        return;
      }

      const { id } = await response.json();

      // Redirect user to Stripe Checkout
      const result = await stripe.redirectToCheckout({
        sessionId: id,
      });

      if (result.error) {
        console.error(result.error.message);
        alert(`Stripe Error: ${result.error.message}`);
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      alert('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const isPlanActive = (priceId) => {
    return activePlans.includes(priceId);
  };

  return (
    <div className="pricing-section">
      <h1>Pricing Plans</h1>
      <p>Choose a plan that suits your needs.</p>
      <div className="pricing-cards">
        {plans.map((plan, index) => (
          <div key={index} className={`pricing-card ${plan.popular ? 'popular' : ''}`}>
            {plan.popular && <div className="badge">Most Popular</div>}
            <h2>{plan.name}</h2>
            <div className="price">{plan.price}</div>
            <ul>
              {plan.features.map((feature, idx) => (
                <li key={idx}>{feature}</li>
              ))}
            </ul>
            {!isPlanActive(plan.priceId) ? (
              <button onClick={() => handleCheckout(plan.priceId)} disabled={loading}>
                {loading ? 'Processing...' : 'Choose Plan'}
              </button>
            ) : (
              <button className="active-button" disabled>
                You already have this plan
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Pricing;
