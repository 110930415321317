// src/components/HtmlPreview.js
import React, { useEffect, useRef } from 'react';

const HtmlPreview = ({ html }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
    iframeDocument.open();
    iframeDocument.write(html);
    iframeDocument.close();
  }, [html]);

  return (
    <div className="preview-container">
      <h2>Preview</h2>
      <div className="preview-content">
        <div className="preview-area">
          <iframe
            ref={iframeRef}
            title="HTML Preview"
            style={{ width: '100%', height: '100%', border: 'none' }}
          />
        </div>
      </div>
    </div>
  );
};

export default HtmlPreview;