// src/components/TermsOfService.js
import React from 'react';
import '../css/TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="terms-container">
      <h1>Terms of Service</h1>

      <p>Last Updated: September 27, 2024</p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing and using GateAI.Email AI Email HTML Generator ("the Service"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use the Service.
      </p>

      <h2>2. Use of the Service</h2>
      <p>
        <strong>2.1 Eligibility</strong><br />
        You must be at least 18 years old to use the Service. By using the Service, you represent and warrant that you meet this age requirement.
      </p>
      <p>
        <strong>2.2 Account Registration</strong><br />
        To use the Service, you must register for an account by providing a valid email address. You are responsible for maintaining the confidentiality of your account credentials and are liable for all activities that occur under your account.
      </p>
      <p>
        <strong>2.3 Subscription Plans</strong><br />
        We offer two subscription plans:
        <ul>
          <li><strong>Standard Plan:</strong> Unlimited use of the Service on a monthly basis. Subscription is billed monthly and remains active until canceled. Upon cancellation, access continues until the end of the current billing cycle.</li>
          <li><strong>Pro Plan:</strong> Unlimited use of the Service with the provision of your own API key on a monthly basis. Subscription is billed monthly and remains active until canceled. Upon cancellation, access continues until the end of the current billing cycle.</li>
        </ul>
      </p>
      <p>
        <strong>2.4 Payment Processing</strong><br />
        All payments are securely processed through <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a>. We do not store or have access to your payment information. For more details, please refer to Stripe's <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
      </p>

      <h2>3. Subscription Cancellation</h2>
      <p>
        You may cancel your subscription at any time through your account dashboard. Upon cancellation, your subscription will remain active until the end of the current billing period.
      </p>

      <h2>4. Access to the Service</h2>
      <p>
        Access to the Service requires an active subscription. Without a valid subscription, you will not be able to use the Service. Subscriptions are non-transferable and non-refundable, except as required by law.
      </p>

      <h2>5. User Responsibilities</h2>
      <p>
        <strong>5.1 Authorized Use</strong><br />
        You agree to use the Service only for lawful purposes and in accordance with these Terms.
      </p>
      <p>
        <strong>5.2 API Keys</strong><br />
        If you choose the Pro Plan, you are responsible for providing and managing your own API keys. We do not use or store your API keys and are not responsible for any misuse or issues arising from their use.
      </p>
      <p>
        <strong>5.3 Content Generation</strong><br />
        You are solely responsible for the content you generate using the Service. We do not control and are not responsible for any content generated by users.
      </p>

      <h2>6. Intellectual Property</h2>
      <p>
        <strong>6.1 User-Generated Content</strong><br />
        You retain ownership of the content you generate using the Service. However, by using the Service, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, and distribute your content as necessary to provide and improve the Service.
      </p>
      <p>
        <strong>6.2 Service Content</strong><br />
        All content, trademarks, logos, and other intellectual property displayed within the Service are the property of GateAI or their respective owners. You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service without our express written permission.
      </p>

      <h2>7. Limitation of Liability</h2>
      <p>
        GateAI.Email AI Email HTML Generator is provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not warrant that the Service will be uninterrupted, error-free, or free of harmful components.
      </p>
      <p>
        In no event shall GateAI, its affiliates, or their respective directors, officers, employees, agents, or suppliers be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Service.
      </p>

      <h2>8. Disclaimer of Warranties</h2>
      <p>
        We do not guarantee the accuracy, completeness, or usefulness of any information provided through the Service. You agree that your use of the Service is at your sole risk.
      </p>

      <h2>9. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless GateAI and its affiliates from and against any and all claims, damages, obligations, losses, liabilities, costs, and expenses arising from:
        <ul>
          <li>Your use of and access to the Service;</li>
          <li>Your violation of any term of these Terms;</li>
          <li>Your violation of any third-party right, including without limitation any copyright, property, or privacy right;</li>
          <li>Any claim that your use of the Service caused damage to a third party.</li>
        </ul>
      </p>

      <h2>10. Privacy</h2>
      <p>
        Your use of the Service is also governed by our <a href="#/privacy">Privacy Policy</a>.
      </p>

      <h2>11. Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws of Moscow, Russia, without regard to its conflict of law provisions.
      </p>

      <h2>12. Dispute Resolution</h2>
      <p>
        Any disputes arising out of or related to these Terms shall be resolved through binding arbitration in Moscow, Russia. You agree to waive any right to a jury trial or to participate in a class action lawsuit.
      </p>

      <h2>13. Changes to Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on the Service. Your continued use of the Service after any modifications constitutes acceptance of the updated Terms.
      </p>

      <h2>14. Termination</h2>
      <p>
        We reserve the right to terminate or suspend your account and access to the Service immediately, without prior notice or liability, for any reason, including if you breach these Terms.
      </p>

      <h2>15. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at <a href="mailto:info@gateai.email">info@gateai.email</a>.
      </p>

      {/* Add additional sections as necessary */}
    </div>
  );
};

export default TermsOfService;
