// src/components/Faq.js
import React from 'react';
import '../css/Faq.css';

const Faq = () => {
  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>

      <div className="faq-item">
        <h3>What is GateAI.Email?</h3>
        <p>GateAI.Email is an AI-powered platform designed to help you generate high-quality email templates effortlessly.</p>
      </div>

      <div className="faq-item">
        <h3>How does the subscription work?</h3>
        <p>We offer multiple subscription plans to cater to your needs. You can upgrade or downgrade your plan at any time from your dashboard.</p>
      </div>

      <div className="faq-item">
        <h3>Can I cancel my subscription?</h3>
        <p>Yes, you can cancel your subscription at any time. Your access will continue until the end of your current billing cycle.</p>
      </div>

      {/* Расширенные вопросы и ответы */}

      <div className="faq-item">
        <h3>What subscription plans are available?</h3>
        <p>We offer two main subscription plans:</p>
        <ul>
          <li><strong>Standard Plan:</strong> Unlimited use of the Service on a monthly basis. Billed monthly and remains active until canceled.</li>
          <li><strong>Pro Plan:</strong> Unlimited use of the Service with the provision of your own API key on a monthly basis. Billed monthly and remains active until canceled.</li>
        </ul>
      </div>

      <div className="faq-item">
        <h3>How do I choose the right plan for me?</h3>
        <p>If you require advanced features and wish to use your own API key, the Pro Plan is ideal. For general use without the need for an API key, the Standard Plan will suffice.</p>
      </div>

      <div className="faq-item">
        <h3>How is payment processed?</h3>
        <p>All payments are securely processed through <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a>. We do not store or have access to your payment information.</p>
      </div>

      <div className="faq-item">
        <h3>What forms of payment do you accept?</h3>
        <p>We accept major credit and debit cards, including Visa, MasterCard, American Express, and Discover. Additionally, Stripe supports various other payment methods depending on your location.</p>
      </div>

      <div className="faq-item">
        <h3>How can I upgrade or downgrade my subscription?</h3>
        <p>You can manage your subscription plans directly from your account dashboard. Navigate to the "Billing" section to upgrade or downgrade your plan as needed.</p>
      </div>

      <div className="faq-item">
        <h3>Is there a free trial available?</h3>
        <p>Currently, we do not offer a free trial. However, you can cancel your subscription at any time before the next billing cycle if you are not satisfied with the service.</p>
      </div>

      <div className="faq-item">
        <h3>How do I generate an email template?</h3>
        <p>Simply enter your prompt in the input field on the homepage and click "Generate". Our AI will create a customized HTML email template based on your input.</p>
      </div>

      <div className="faq-item">
        <h3>Can I export the generated email templates?</h3>
        <p>Yes, you can export your generated templates as HTML files by clicking the "Export HTML" button. This allows you to use the templates in your email marketing campaigns.</p>
      </div>

      <div className="faq-item">
        <h3>Are there any limitations on the number of templates I can generate?</h3>
        <p>With the Standard and Pro Plans, you have unlimited access to generate and export email templates without any restrictions.</p>
      </div>

      <div className="faq-item">
        <h3>What happens to my data after I cancel my subscription?</h3>
        <p>Your generated templates and account information are retained until you choose to delete your account. To permanently remove your data, please contact our support team via <a href="mailto:info@gateai.email">info@gateai.email</a>.</p>
      </div>

      <div className="faq-item">
        <h3>Who owns the content I generate?</h3>
        <p>You retain full ownership of the email templates you generate using GateAI.Email. By using our service, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, and distribute your content as necessary to provide and improve the Service.</p>
      </div>

      <div className="faq-item">
        <h3>Do I need to provide my own API key?</h3>
        <p>Only Pro Plan subscribers have the option to provide and use their own API keys. This allows for greater customization and integration with other services.</p>
      </div>

      <div className="faq-item">
        <h3>How do I reset my password?</h3>
        <p>To reset your password, go to the login page and click on "Forgot Password". Enter your registered email address, and we will send you instructions to reset your password.</p>
      </div>

      <div className="faq-item">
        <h3>Is my personal information secure?</h3>
        <p>Yes, we prioritize the security of your personal information. We implement industry-standard security measures to protect your data from unauthorized access, alteration, disclosure, or destruction.</p>
      </div>

      <div className="faq-item">
        <h3>How can I contact support?</h3>
        <p>You can reach our support team by emailing <a href="mailto:info@gateai.email">info@gateai.email</a>. We aim to respond to all inquiries within 24 hours.</p>
      </div>

      <div className="faq-item">
        <h3>Do you offer refunds?</h3>
        <p>Refunds are provided on a case-by-case basis. If you are unsatisfied with our service, please contact our support team via <a href="mailto:info@gateai.email">info@gateai.email</a> to discuss your situation.</p>
      </div>

      <div className="faq-item">
        <h3>Can I use the generated templates for commercial purposes?</h3>
        <p>Yes, you can use the generated email templates for both personal and commercial purposes. However, you must comply with all applicable laws and regulations.</p>
      </div>

      <div className="faq-item">
        <h3>Are there any hidden fees?</h3>
        <p>No, there are no hidden fees. All costs associated with your subscription are clearly outlined in your account dashboard and our <a href="#/pricing">pricing page</a>.</p>
      </div>

      <div className="faq-item">
        <h3>How often do you update the service?</h3>
        <p>We regularly update our service to introduce new features, improve performance, and enhance security. Subscribers are notified of major updates and changes.</p>
      </div>

      {/* Добавьте дополнительные вопросы и ответы по мере необходимости */}
    </div>
  );
};

export default Faq;
