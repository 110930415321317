// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { HashRouter as Router } from 'react-router-dom';
import ConsentBanner from './ConsentBanner'; // Импортируем компонент ConsentBanner

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
      <ConsentBanner /> {/* Добавляем баннер согласия */}
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
