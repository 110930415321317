// src/components/Register.js
import React, { useState } from 'react';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { toast } from 'react-toastify';
import '../css/Auth.css';

function Register({ setShowRegister, setShowLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      // Регистрация через Firebase
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Отправка письма для подтверждения почты
      await sendEmailVerification(user);
      toast.info('Verification email sent. Please check your inbox.');

      // Получение токена для бэкенда
      const token = await user.getIdToken();

      // Отправка запроса на бэкенд для регистрации пользователя в MongoDB
      // Теперь, поскольку `/api/user-dashboard` автоматически создаёт пользователя, запрос к `/api/register` не требуется
      /*
      const response = await fetch('https://gateai.email/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        toast.success('Registration successful! Please verify your email.');
        setShowRegister(false);
      } else {
        const errorData = await response.json();
        toast.error(`Error registering: ${errorData.error}`, { position: 'bottom-right' });
        // Выход из системы, если регистрация на бэкенде не удалась
        await auth.signOut();
      }
      */

      // Теперь просто закрываем модальное окно, а пользователь должен подтвердить почту
      toast.success('Registration successful! Please verify your email.');
      setShowRegister(false);
    } catch (error) {
      console.error("Error registering:", error);
      toast.error("Error registering. Please try again.");
    }
  };

  return (
    <div className="auth-container">
      <h1 className="auth-title">Create Account</h1>
      <form className="auth-form" onSubmit={handleRegister}>
        <input
          type="email"
          className="auth-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          type="password"
          className="auth-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          autoComplete="new-password"
          required
        />
        <button type="submit" className="auth-button">Sign Up</button>
      </form>
      <p className="auth-text">
        Already have an account?{' '}
        <span className="auth-link" onClick={() => {
          setShowRegister(false);
          setShowLogin(true);
        }}>
          Login
        </span>
      </p>
    </div>
  );
}

export default Register;
