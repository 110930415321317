// src/ConsentBanner.js
import React, { useEffect } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';

const ConsentBanner = () => {
  useEffect(() => {
    const consent = Cookies.get('CookieConsent');
    if (consent === 'true') {
      loadGA();
    }
  }, []);

  const handleAccept = () => {
    loadGA();
  };

  const loadGA = () => {
    const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

    if (!GA_TRACKING_ID) {
      console.warn('Google Analytics Tracking ID не установлен.');
      return;
    }

    // Проверяем, был ли уже загружен скрипт
    if (document.querySelector(`script[src="https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}"]`)) {
      return;
    }

    // Загружаем скрипт GA
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      window.gtag = gtag;
      gtag('js', new Date());

      gtag('config', GA_TRACKING_ID, {
        page_path: window.location.pathname,
      });
    };
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      enableDeclineButton
      onAccept={handleAccept}
      onDecline={() => {
        console.log('User declined cookies');
      }}
      cookieName="CookieConsent"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      declineButtonStyle={{ color: "#ffffff", background: "#d33" }}
      expires={150}
    >
      This website uses cookies to enhance the user experience. By clicking "Accept", you agree to the use of cookies.
    </CookieConsent>
  );
};

export default ConsentBanner;
