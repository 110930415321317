import React, { useState, useEffect } from 'react';
import { auth } from '../firebase'; // Импортируем Firebase auth
import axios from 'axios';
import '../css/Feedback.css';

const Feedback = () => {
  const [topic, setTopic] = useState('Bug Report');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState(''); // Добавлено состояние для email
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [ipAddress, setIpAddress] = useState('Unknown IP');
  const [currentUser, setCurrentUser] = useState(null); // Для хранения данных пользователя

  // Телеграмм бот токен и идентификатор чата
  const TELEGRAM_BOT_TOKEN = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
  const TELEGRAM_CHAT_ID = process.env.REACT_APP_TELEGRAM_CHAT_ID;

  // Получаем IP-адрес пользователя
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIpAddress();
  }, []);

  // Получаем текущего залогиненного пользователя через Firebase
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setCurrentUser(user);
      setEmail(user.email || ''); // Предзаполняем email, если пользователь авторизован
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Валидация email (опционально)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email)) {
      alert('Пожалуйста, введите корректный email.');
      setIsSubmitting(false);
      return;
    }

    const messageToSend = `Topic: ${topic}\nMessage: ${message}\nEmail: ${email || (currentUser ? currentUser.email : 'Anonymous')}\nIP Address: ${ipAddress}`;

    try {
      const response = await fetch(`https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: TELEGRAM_CHAT_ID,
          text: messageToSend,
        }),
      });

      if (response.ok) {
        setSuccess(true);
        setMessage(''); // Очищаем сообщение после успешной отправки
        if (!currentUser) {
          setEmail(''); // Очищаем email, если пользователь не авторизован
        }
      } else {
        setSuccess(false);
      }
    } catch (error) {
      console.error('Error sending message to Telegram:', error);
      setSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="feedback-container">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit} className="feedback-form">
        <label>
          Topic:
          <select value={topic} onChange={(e) => setTopic(e.target.value)}>
            <option value="Bug Report">Bug Report</option>
            <option value="Feature Request">Feature Request</option>
            <option value="Partnership">Partnership</option>
            <option value="Other">Other</option>
          </select>
        </label>
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            disabled={isSubmitting}
            required // Сделать поле обязательным (опционально)
          />
        </label>
        <label>
          Message:
          <textarea
            rows="6"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter your message here..."
            disabled={isSubmitting}
            required // Сделать поле обязательным (опционально)
          />
        </label>
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      {success === true && <p>Feedback successfully sent!</p>}
      {success === false && <p>Error sending feedback. Please try again later.</p>}
    </div>
  );
};

export default Feedback;
