// src/components/UserDashboard.js
import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import '../css/UserDashboard.css';
import { toast } from 'react-toastify';

function UserDashboard() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [canceling, setCanceling] = useState({});
  const [editingApiKey, setEditingApiKey] = useState({});
  const [apiKeyInputs, setApiKeyInputs] = useState({});
  const [showApiKeys, setShowApiKeys] = useState({});
  const [updatingApiKey, setUpdatingApiKey] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          navigate('/login');
          return;
        }

        const token = await user.getIdToken();

        const response = await fetch('/api/user-dashboard', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserData(data);
          // Initialize API key inputs for PRO subscriptions
          const initialApiKeys = {};
          data.subscriptions.forEach(sub => {
            if (sub.plan === process.env.REACT_APP_PRICE_PRO && sub.apiKey) {
              initialApiKeys[sub._id] = sub.apiKey;
            }
          });
          setApiKeyInputs(initialApiKeys);
        } else {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.error}`, { position: 'bottom-right' });
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('Failed to load user data.', { position: 'bottom-right' });
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleCancelSubscription = async (subscriptionId) => {
    if (!window.confirm('Are you sure you want to cancel this subscription?')) {
      return;
    }

    setCanceling(prev => ({ ...prev, [subscriptionId]: true }));
    try {
      const user = auth.currentUser;
      if (!user) {
        navigate('/login');
        return;
      }

      const token = await user.getIdToken();

      const response = await fetch('/api/cancel-subscription', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ subscriptionId }),
      });

      if (response.ok) {
        toast.success('Subscription cancellation scheduled successfully.', { position: 'bottom-right' });
        // Update user data locally to reflect the cancellation
        setUserData(prevData => ({
          ...prevData,
          subscriptions: prevData.subscriptions.map(sub =>
            sub._id === subscriptionId ? { ...sub, cancelAtPeriodEnd: true } : sub
          ),
        }));
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error}`, { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while canceling the subscription.', { position: 'bottom-right' });
    } finally {
      setCanceling(prev => ({ ...prev, [subscriptionId]: false }));
    }
  };

  const handleEditApiKey = (subscriptionId) => {
    setEditingApiKey(prev => ({ ...prev, [subscriptionId]: true }));
  };

  const handleSaveApiKey = async (subscriptionId) => {
    if (editingApiKey[subscriptionId] && !apiKeyInputs[subscriptionId].trim()) {
      if (!window.confirm('Are you sure you want to remove your API key?')) {
        return;
      }
    }

    setUpdatingApiKey(prev => ({ ...prev, [subscriptionId]: true }));
    try {
      const user = auth.currentUser;
      if (!user) {
        navigate('/login');
        return;
      }

      const token = await user.getIdToken();

      const payload = {
        apiKey: apiKeyInputs[subscriptionId].trim() || null,
        subscriptionId
      };

      const response = await fetch('/api/update-api-key', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const successData = await response.json();
        toast.success(successData.message, { position: 'bottom-right' });
        // Update user data
        setUserData(prevData => ({
          ...prevData,
          subscriptions: prevData.subscriptions.map(sub =>
            sub._id === subscriptionId ? { ...sub, apiKey: apiKeyInputs[subscriptionId].trim() || null } : sub
          ),
        }));
        setEditingApiKey(prev => ({ ...prev, [subscriptionId]: false }));
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error}`, { position: 'bottom-right' });
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to update API key.', { position: 'bottom-right' });
    } finally {
      setUpdatingApiKey(prev => ({ ...prev, [subscriptionId]: false }));
    }
  };

  const handleCancelEditApiKey = (subscriptionId) => {
    // Reset API key input to original value
    const subscription = userData.subscriptions.find(sub => sub._id === subscriptionId);
    if (subscription && subscription.apiKey) {
      setApiKeyInputs(prev => ({ ...prev, [subscriptionId]: subscription.apiKey }));
    } else {
      setApiKeyInputs(prev => ({ ...prev, [subscriptionId]: '' }));
    }
    setEditingApiKey(prev => ({ ...prev, [subscriptionId]: false }));
  };

  const handleApiKeyChange = (subscriptionId, value) => {
    setApiKeyInputs(prev => ({ ...prev, [subscriptionId]: value }));
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userData) {
    return <div>User data not found.</div>;
  }

  const { email, subscriptions } = userData;

  // Group active subscriptions by plan
  const activeSubscriptions = subscriptions.filter(sub => sub.status === 'active');
  const groupedActiveSubscriptions = activeSubscriptions.reduce((acc, sub) => {
    if (!acc[sub.plan]) acc[sub.plan] = [];
    acc[sub.plan].push(sub);
    return acc;
  }, {});

  // Group canceled subscriptions by plan
  const canceledSubscriptions = subscriptions.filter(sub => sub.status === 'canceled');
  const groupedCanceledSubscriptions = canceledSubscriptions.reduce((acc, sub) => {
    if (!acc[sub.plan]) acc[sub.plan] = [];
    acc[sub.plan].push(sub);
    return acc;
  }, {});

  const getTariffName = (priceId) => {
    const PRICE_STANDARD = process.env.REACT_APP_PRICE_STANDARD;
    const PRICE_PRO = process.env.REACT_APP_PRICE_PRO;
    switch (priceId) {
      case PRICE_STANDARD:
        return 'Standard';
      case PRICE_PRO:
        return 'PRO';
      default:
        return 'Active';
    }
  };

  const getUsageInfo = (subscription) => {
    if (!subscription) return 'No active subscription';
    if (subscription.usageLimit === Infinity) return 'Unlimited';
    return `${subscription.usageCount} `;
  };

  return (
    <div className="user-dashboard">
      <h2>User Dashboard</h2>
      <div className="user-info">
        <p><strong>Email:</strong> {email}</p>
      </div>
      {Object.keys(groupedActiveSubscriptions).length > 0 ? (
        Object.keys(groupedActiveSubscriptions).map(planId => (
          <div key={planId} className="plan-section">
            <h3>Plan: {getTariffName(planId)}</h3>
            {groupedActiveSubscriptions[planId].map(subscription => (
              <div key={subscription._id} className="subscription-info">
                <p><strong>Status:</strong> {subscription.status}</p>
                <p><strong>Generation Count:</strong> {getUsageInfo(subscription)}</p>
                {!subscription.cancelAtPeriodEnd ? (
                  <button onClick={() => handleCancelSubscription(subscription._id)} disabled={canceling[subscription._id]}>
                    {canceling[subscription._id] ? 'Canceling...' : 'Cancel Subscription'}
                  </button>
                ) : (
                  <p className="cancellation-info">
                    Subscription is set to cancel at the end of the current period on <strong>{formatDate(subscription.currentPeriodEnd)}</strong>.
                  </p>
                )}
                {subscription.plan === process.env.REACT_APP_PRICE_PRO && (
                  <div className="api-key-section">
                    <h4>API Key</h4>
                    {!editingApiKey[subscription._id] ? (
                      <div className="api-key-display">
                        <input
                          type={showApiKeys[subscription._id] ? 'text' : 'password'}
                          value={apiKeyInputs[subscription._id] || ''}
                          readOnly
                        />
                        <button onClick={() => setShowApiKeys(prev => ({ ...prev, [subscription._id]: !prev[subscription._id] }))}>
                          {showApiKeys[subscription._id] ? 'Hide Key' : 'Reveal Key'}
                        </button>
                        <button onClick={() => handleEditApiKey(subscription._id)}>Edit</button>
                      </div>
                    ) : (
                      <div className="api-key-edit">
                        <input
                          type={showApiKeys[subscription._id] ? 'text' : 'password'}
                          value={apiKeyInputs[subscription._id]}
                          onChange={(e) => handleApiKeyChange(subscription._id, e.target.value)}
                          placeholder="Enter your OpenAI API Key"
                        />
                        <button onClick={() => setShowApiKeys(prev => ({ ...prev, [subscription._id]: !prev[subscription._id] }))}>
                          {showApiKeys[subscription._id] ? 'Hide Key' : 'Reveal Key'}
                        </button>
                        <button onClick={() => handleSaveApiKey(subscription._id)} disabled={updatingApiKey[subscription._id]}>
                          {updatingApiKey[subscription._id] ? 'Saving...' : 'Save'}
                        </button>
                        <button onClick={() => handleCancelEditApiKey(subscription._id)} disabled={updatingApiKey[subscription._id]}>
                          Cancel
                        </button>
                        {apiKeyInputs[subscription._id] && (
                          <button onClick={() => {
                            if (window.confirm('Are you sure you want to remove your API key?')) {
                              setApiKeyInputs(prev => ({ ...prev, [subscription._id]: '' }));
                              handleSaveApiKey(subscription._id);
                            }
                          }} disabled={updatingApiKey[subscription._id]}>
                            Remove API Key
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))
      ) : (
        <div className="no-active-subscription">
          <p>No active subscriptions. Please subscribe to a plan.</p>
          <button onClick={() => navigate('/pricing')}>Go to Pricing</button>
        </div>
      )}

      {Object.keys(groupedCanceledSubscriptions).length > 0 && (
        <div className="canceled-subscriptions">
          <h3>Canceled Subscriptions</h3>
          {Object.keys(groupedCanceledSubscriptions).map(planId => (
            <div key={planId} className="plan-section">
              <h4>Plan: {getTariffName(planId)}</h4>
              {groupedCanceledSubscriptions[planId].map(subscription => (
                <div key={subscription._id} className="subscription-info canceled">
                  <p><strong>Status:</strong> {subscription.status}</p>
                  <p><strong>Generation Count:</strong> {getUsageInfo(subscription)}</p>
                  {subscription.cancelAtPeriodEnd ? (
                    <p className="cancellation-info">
                      Subscription is active until <strong>{formatDate(subscription.currentPeriodEnd)}</strong>.
                    </p>
                  ) : (
                    <p className="cancellation-info">
                      Subscription was canceled immediately.
                    </p>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default UserDashboard;
