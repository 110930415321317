const templates = [
  {
    name: 'Product Launch',
    html: `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
          body { font-family: 'Poppins', Arial, sans-serif; margin: 0; padding: 0; background-color: #f8f9fa; }
          .container { max-width: 600px; margin: auto; background: linear-gradient(to bottom right, #ffffff, #f0f0f0); padding: 30px; box-shadow: 0 10px 30px rgba(0,0,0,0.1); }
          h1 { color: #2c3e50; font-size: 28px; margin-bottom: 20px; }
          p { color: #34495e; line-height: 1.8; margin-bottom: 20px; }
          .button { background: linear-gradient(to right, #3498db, #2980b9); color: white; padding: 12px 24px; text-align: center; display: inline-block; margin-top: 20px; border-radius: 50px; text-decoration: none; font-weight: 600; transition: all 0.3s ease; }
          .button:hover { transform: translateY(-3px); box-shadow: 0 5px 15px rgba(0,0,0,0.1); }
          .product-image { width: 100%; height: auto; border-radius: 10px; margin-bottom: 20px; }
          @media (max-width: 600px) {
            .container { padding: 20px; }
            h1 { font-size: 24px; }
          }
        </style>
      </head>
      <body>
        <div class="container">
          <h1>Introducing Our Revolutionary Product</h1>
          <img src="https://via.placeholder.com/600x300" alt="New Product" class="product-image">
          <p>We are thrilled to unveil our latest innovation that's set to transform your daily life. Packed with cutting-edge features and sleek design, it's not just a product—it's a game-changer.</p>
          <p>Be among the first to experience the future. Limited stock available!</p>
          <a href="#" class="button">Explore Now</a>
        </div>
      </body>
      </html>
    `
  },
  {
    name: 'Newsletter',
    html: `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
          body { font-family: 'Roboto', Arial, sans-serif; margin: 0; padding: 0; background-color: #f4f4f4; }
          .container { max-width: 600px; margin: auto; background: #ffffff; padding: 30px; box-shadow: 0 0 20px rgba(0,0,0,0.1); }
          h1 { color: #2c3e50; font-size: 24px; border-bottom: 2px solid #3498db; padding-bottom: 10px; }
          h2 { color: #34495e; font-size: 20px; }
          p { color: #555; line-height: 1.6; }
          .article { margin-bottom: 30px; }
          .footer { margin-top: 30px; text-align: center; font-size: 14px; color: #7f8c8d; border-top: 1px solid #ecf0f1; padding-top: 20px; }
          .social-links { margin-top: 20px; }
          .social-links a { display: inline-block; margin: 0 10px; }
          @media (max-width: 600px) {
            .container { padding: 20px; }
          }
        </style>
      </head>
      <body>
        <div class="container">
          <h1>Monthly Insights</h1>
          <div class="article">
            <h2>Industry Trends</h2>
            <p>Discover the latest shifts in our industry and how they might impact your business. Our experts break down complex trends into actionable insights.</p>
          </div>
          <div class="article">
            <h2>Success Story</h2>
            <p>Learn how our client achieved a 200% ROI using our solutions. Get inspired and find out how you can replicate their success.</p>
          </div>
          <div class="article">
            <h2>Upcoming Webinar</h2>
            <p>Join us for an exclusive webinar on "Future-Proofing Your Business". Limited spots available!</p>
          </div>
          <div class="footer">
            <p>© ${new Date().getFullYear()} Your Company. All rights reserved.</p>
            <div class="social-links">
              <a href="#">Facebook</a>
              <a href="#">Twitter</a>
              <a href="#">LinkedIn</a>
            </div>
          </div>
        </div>
      </body>
      </html>
    `
  },
  {
    name: 'Event Invitation',
    html: `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
          body { font-family: 'Montserrat', sans-serif; margin: 0; padding: 0; background-color: #f9f9f9; }
          .container { max-width: 600px; margin: auto; background: #ffffff; padding: 40px; box-shadow: 0 0 30px rgba(0,0,0,0.1); }
          .header { background: linear-gradient(45deg, #FF4B2B, #FF416C); color: white; padding: 30px; text-align: center; border-radius: 10px 10px 0 0; }
          h1 { margin: 0; font-size: 28px; }
          .content { padding: 30px 0; }
          p { color: #333; line-height: 1.6; }
          .button { background-color: #FF4B2B; color: white; padding: 15px 30px; text-align: center; display: inline-block; border-radius: 50px; text-decoration: none; font-weight: bold; margin-top: 20px; transition: all 0.3s ease; }
          .button:hover { background-color: #FF416C; }
          .footer { margin-top: 30px; text-align: center; font-size: 14px; color: #888; }
          @media (max-width: 600px) {
            .container { padding: 20px; }
            .header { padding: 20px; }
          }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="header">
            <h1>You're Invited!</h1>
          </div>
          <div class="content">
            <p>Join us for an exclusive evening of networking and insights at our annual Tech Summit.</p>
            <p><strong>Date:</strong> September 15, 2024<br>
            <strong>Time:</strong> 7:00 PM - 10:00 PM<br>
            <strong>Venue:</strong> Grand Ballroom, Luxe Hotel</p>
            <p>Hear from industry leaders, enjoy gourmet refreshments, and be part of shaping the future of technology.</p>
            <a href="#" class="button">RSVP Now</a>
          </div>
          <div class="footer">
            <p>We look forward to seeing you there!</p>
          </div>
        </div>
      </body>
      </html>
    `
  },
  {
    name: 'Promotional Offer',
    html: `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
          body { font-family: 'Open Sans', Arial, sans-serif; margin: 0; padding: 0; background-color: #f0f0f0; }
          .container { max-width: 600px; margin: auto; background: #ffffff; padding: 0; box-shadow: 0 0 20px rgba(0,0,0,0.1); }
          .header { background-color: #4CAF50; color: white; padding: 30px; text-align: center; }
          h1 { margin: 0; font-size: 32px; }
          .content { padding: 30px; }
          p { color: #333; line-height: 1.6; }
          .offer { background-color: #FFF59D; padding: 20px; border-radius: 10px; text-align: center; margin: 20px 0; }
          .code { font-size: 24px; font-weight: bold; color: #FF5722; letter-spacing: 2px; }
          .button { background-color: #FF5722; color: white; padding: 15px 30px; text-align: center; display: inline-block; border-radius: 5px; text-decoration: none; font-weight: bold; margin-top: 20px; transition: all 0.3s ease; }
          .button:hover { background-color: #E64A19; }
          .footer { background-color: #333; color: white; text-align: center; padding: 20px; font-size: 14px; }
          @media (max-width: 600px) {
            .header { padding: 20px; }
            .content { padding: 20px; }
          }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="header">
            <h1>Flash Sale!</h1>
          </div>
          <div class="content">
            <p>For 48 hours only, enjoy massive discounts on our entire catalog!</p>
            <div class="offer">
              <p>Use code</p>
              <p class="code">FLASH50</p>
              <p>for 50% off your purchase</p>
            </div>
            <p>Don't miss this opportunity to grab your favorite items at unbeatable prices.</p>
            <a href="#" class="button">Shop Now</a>
          </div>
          <div class="footer">
            <p>© ${new Date().getFullYear()} Your Company. All rights reserved.</p>
            <p>You're receiving this email because you've subscribed to our promotional offers.</p>
          </div>
        </div>
      </body>
      </html>
    `
  }
];

export default templates;
