// src/components/HtmlEditor.js
import React, { useEffect, useRef } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/htmlmixed/htmlmixed';

const HtmlEditor = ({ value, onChange, theme }) => {
  const editorRef = useRef(null);
  const scrollPosition = useRef(0);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.scrollTo(0, scrollPosition.current);
    }
  }, [value]);

  return (
    <div className="editor-container">
      <h2>HTML Editor</h2>
      <div className="editor-content">
        <CodeMirror
          value={value}
          options={{
            mode: 'htmlmixed',
            theme: theme,
            lineNumbers: true,
            lineWrapping: true,
          }}
          onBeforeChange={(editor, data, value) => {
            onChange(value);
          }}
          editorDidMount={(editor) => {
            editorRef.current = editor;
          }}
          onScroll={(editor, data) => {
            scrollPosition.current = data.top;
          }}
        />
      </div>
    </div>
  );
};

export default HtmlEditor;